
import { computed, defineComponent, onMounted, ref } from 'vue'
import axios from '@/http'
import { IResListWrap, ISizeType, ISizeValue } from '@/interfaces'
import { debounce } from '@/utils/debounce'
import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
	name: 'AddSizeValue',

	setup() {
		const sizeTypes = ref<IResListWrap<ISizeType>>()
		const sizeValues = ref<ISizeValue[] | [] | null>(null)
		const sizeTypeModel = ref<string>('')
		const sizeValueNameModel = ref<string>('')
		const sizeValueKeyModel = ref<string>('')
		const isUniqName = ref<boolean>(false)
		const isUniqKey = ref<boolean>(false)

		const isSave = computed(
			() =>
				!!(sizeTypeModel.value && sizeValueNameModel.value && sizeValueKeyModel && isUniqName.value && isUniqKey.value)
		)

		onMounted(async () => {
			try {
				sizeTypes.value = (
					await axios.post<IResListWrap<ISizeType>>('/internal/get-size-types-list', { page: 0, limit: 300 })
				).data
			} catch (e) {}
		})

		function sizeValueKeyModelInput(str: string) {
			sizeValueKeyModel.value = str.replace(/[^(a-z|0-9|\-|_)]/g, '')

			if (!sizeValueKeyModel.value) return (isUniqKey.value = false)
			checkSizeValueByKey(sizeValueKeyModel.value)
		}

		function sizeValueNameModelInput() {
			if (!sizeValueNameModel.value) return (isUniqName.value = false)
			checkSizeValueByName(sizeValueNameModel.value)
		}

		function confirmSave() {
			ElMessageBox.confirm(
				'Хотите добавить значение размера: ' + sizeValueNameModel.value + ' и ключ: ' + sizeValueKeyModel.value,
				'',
				{
					boxType: 'confirm',
					type: 'info',
					confirmButtonText: 'Сохранить',
					cancelButtonText: 'Отменить'
				}
			).then(() => save())
		}

		async function save() {
			const data: Omit<ISizeValue, 'id'> = {
				key: sizeValueKeyModel.value,
				name: sizeValueNameModel.value,
				sizeTypeId: Number(sizeTypeModel.value)
			}

			try {
				await createSizeValue(data)

				ElMessage.success('Размер сохранен')

				reset()
			} catch (e) {
				ElMessage.error('Не удалось создать размер')
			}
		}

		function reset() {
			sizeValueKeyModel.value = ''
			sizeValueNameModel.value = ''
			sizeTypeModel.value = ''
			isUniqKey.value = false
			isUniqName.value = false

			resetSizeValues()
		}

		const checkSizeValueByKey = debounce(200, async name => {
			const res = await apiCheckSizeValueByKey(name)

			if (res) {
				isUniqKey.value = false

				ElMessage.error('Ключ уже существует')
			} else isUniqKey.value = true
		})

		async function apiCheckSizeValueByKey(str: string): Promise<ISizeValue | null> {
			try {
				return (await axios.get<ISizeValue | null>('/internal/check-size-value-by-key/' + str)).data
			} catch (e) {
				return null
			}
		}

		const checkSizeValueByName = debounce(200, async name => {
			const res = await apiCheckSizeValueByName(name)

			if (res) {
				isUniqName.value = false

				ElMessage.error('Значение размера уже существует')
			} else isUniqName.value = true
		})

		async function apiCheckSizeValueByName(str: string): Promise<ISizeValue | null> {
			try {
				return (await axios.get<ISizeValue | null>('/internal/check-size-value-by-name/' + str)).data
			} catch (e) {
				return null
			}
		}

		async function resetSizeValues() {
			if (!sizeTypeModel.value) return (sizeValues.value = null)

			sizeValues.value = await getSizeValuesBySizeTypeId(+sizeTypeModel.value)
		}

		async function getSizeValuesBySizeTypeId(id: number): Promise<ISizeValue[] | [] | null> {
			try {
				return (await axios.get<ISizeValue[] | []>('/internal/get-size-values-by-size-type-id/' + id)).data
			} catch (e) {
				return null
			}
		}

		async function createSizeValue(data: Omit<ISizeValue, 'id'>) {
			await axios.post('/internal/create-size-value', data)
		}

		return {
			isSave,
			sizeTypes,
			sizeValues,
			sizeTypeModel,
			sizeValueNameModel,
			sizeValueKeyModel,

			isUniqName,
			isUniqKey,

			confirmSave,
			resetSizeValues,
			sizeValueKeyModelInput,
			sizeValueNameModelInput
		}
	}
})
